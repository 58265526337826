export const EVENTOS_SOCKET = {
    ALTERAR_STATUS_ATENDIMENTO_VIDEO: 'alterar_status_atendimento_video',
    ATENDENTE_CONECTADO: 'atendente_conectado',
    ATENDENTE_DESCONECTADO: 'atendente_desconectado',
    ATENDENTE_ONLINE: 'atendente_online',
    ATENDIDO_DESCONECTADO: 'atendido_desconectado',
    ATENDIMENTO_ENCERRADO: 'atendimento_encerrado',
    AVISO_DIGITANDO: 'aviso_digitando',
    CONNECTION: 'connection',
    DADOS_USUARIO: 'dados_usuario',
    DISCONNECT: 'disconnect',
    ENCAMINHAR_ATENDIMENTO: 'encaminhar_atendimento',
    ENVIAR_ANEXO: 'enviar_anexo',
    ENVIAR_AVISO_DIGITANDO: 'enviar_aviso_digitando',
    ENVIAR_MENSAGEM: 'enviar_mensagem',
    FINALIZAR_ATENDIMENTO: 'finalizar_atendimento',
    ID_SALA_ATENDIMENTO: 'id_sala_atendimento',
    INICIAR_ATENDIMENTO: 'iniciar_atendimento',
    LISTA_ATENDENTES: 'lista_atendentes',
    LISTA_PARA_ATENDIMENTO: 'lista_para_atendimento',
    MENSAGEM_ENVIADA: 'mensagem_enviada',
    MENSAGENS_SALA: 'mensagens_sala',
    NOTIFICACAO_SOLICITACAO_ATENDIMENTO: 'notificacao_solicitacao_atendimento',
    SAIR_ATENDIMENTO: 'sair_atendimento',
    SOLICITAR_ATENDIMENTO: 'solicitar_atendimento',
    SOLICITAR_NOVO_ATENDIMENTO: 'solicitar_novo_atendimento',
    STATUS_ATENDIMENTO_VIDEO: 'status_atendimento_video',
    STATUS_ATENDIMENTO_VIDEO_INICIAL: 'status_atendimento_video_inicial',
    USUARIO_DESCONETADO: 'usuario_desconectado',
    USUARIO_ONLINE: 'usuario_online'
};

export const PERMISSOES = {
    TELEATENDIMENTO: 'TELEATENDIMENTO_TELEATENDIMENTO',
    ATENDIMENTO: 'TELEATENDIMENTO_ATENDIMENTO',
}

export const VINCULO_PERFIL_INSTITUICAO = 'I';
export const VINCULO_PERFIL_ESTADUAL = 'E';

const situacaoAtendimento = {
    ESPERA: 0,
    SOLICITOU_ATENDIMENTO: 5,
    EM_ATENDIMENTO: 10,
    ENCAMINHADO: 15,
    CONCLUIDO: 20,
    HISTORICO: 25
}

export const SITUACOES_ATENDIMENTO = {
    SITUACAO: { ...situacaoAtendimento },
    CHIP: {
        [situacaoAtendimento.ESPERA]: {
            descricao: 'Disponível',
            cor: '#FFE599'
        },
        [situacaoAtendimento.SOLICITOU_ATENDIMENTO]: {
            descricao: 'Solicitado',
            cor: '#E06666'
        },
        [situacaoAtendimento.EM_ATENDIMENTO]: {
            descricao: 'Ativo',
            cor: '#70C1B3'
        },
        [situacaoAtendimento.ENCAMINHADO]: {
            descricao: 'Encaminhado',
            cor: '#92A8D1'
        },
        [situacaoAtendimento.CONCLUIDO]: {
            descricao: 'Concluído',
            cor: '#D9D9D9'
        },
        [situacaoAtendimento.HISTORICO]: {
            descricao: 'Histórico',
            cor: '#6D6875'
        },

    }
}

export const EXTENSOES = {
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
    BMP: 'bmp',
};

const desfechos = {
    MANEJO_APS: 'M',
    ENCAMINHAMENTO_ESPECIALISTA: 'E',
    FINALIZADO: 'F',
    RETORNO_ONLINE: 'R'
}

export const DESFECHOS_ATENDIMENTO = [
    { descricao: 'Manejo na APS', id: desfechos.MANEJO_APS },
    { descricao: 'Encaminhamento ao especialista', id: desfechos.ENCAMINHAMENTO_ESPECIALISTA },
    { descricao: 'Finalizado', id: desfechos.FINALIZADO },
    { descricao: 'Retorno online', id: desfechos.RETORNO_ONLINE },
]